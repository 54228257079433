<template>
    <div class="h-screen overflow-auto">
        <div
            class="relative flex min-h-full bg-neutral-100 dark:bg-gray-900 flex-1 flex-col items-center justify-center py-12 sm:px-6 lg:px-8">
            <LogoImage class="h-10" />
            <div class="mt-12 sm:mx-auto sm:w-full sm:max-w-[520px]">
                <div class="bg-white dark:bg-gray-800 px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <ComingSoonPage
                        v-if="underConstruction"
                        small-padding
                        hide-dashboard-link />
                    <slot v-else />
                </div>
            </div>
            <FooterLinks />
        </div>
    </div>
</template>

<script setup>
const underConstruction = computed(() => useRuntimeConfig()?.public?.underConstruction)
</script>
